import React, { useEffect, Component } from 'react';
import { Widget, addResponseMessage, setQuickButtons, addUserMessage, handleQuickButtonClicked, renderCustomComponent } from 'react-chat-widget';
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid';

import 'react-chat-widget/lib/styles.css';
import logoEmpresa from './assets/logoEmpresa.png';
import avatar from './assets/avatarChatBot.png';
import iconoChat from './assets/iconoChat.png';

import callAPI from './api';

class Image extends Component {
    render() {
        return <img alt="placeholder" width="200" height="200" src={this.props.src}></img>
    }
}

function App() {
    const valorButton = async (opciones) => {
        let newOptions = []
        opciones.forEach(element =>{
            element.value = { val: element.value, sms: element.label}
            newOptions.push(element)
            
    });
        return newOptions
    };


    const handleSubmit = async (mensaje) => {
        try {
            let conversationId = Cookies.get("conversationId")
            if (Cookies.get("conversationId") === undefined) {

                Cookies.set("conversationId", uuidv4(), { expires: 5 })
                conversationId = Cookies.get("conversationId")
            }
            const message = {
                text: mensaje,
                isBot: false,
                conversationId: conversationId
            }
            return await callAPI(message.text, message.conversationId) //llamar api handleMessageSubmit(message.text, message.conversationId);


        } catch (error) {
            console.log("esta sucediendo un error:", error)
            addResponseMessage('Ha ocurrido un error');
        }
    };
    // Para mostrar un mensaje apenas inicia
    useEffect(() => {
        addResponseMessage('Hola para acceder a nuestro menu de opciones ingresa la palabra "menú"');
    }, []);

    const handleNewUserMessage = async (newMessage) => {

        let result = await handleSubmit(newMessage)

        if (result.data.result.tipoMensaje === "texto") {
            addResponseMessage(result.data.result.texto);
        } else {
            if (result.data.result.tipoMensaje === "image") {
                addResponseMessage(result.data.result.texto);
                renderCustomComponent(Image, { src: result.data.result.image }, true, "response")
            } else {
                addResponseMessage(result.data.result.mensaje)
                if (result.data.result.indicacion !== "") {
                    addResponseMessage(result.data.result.indicacion)
                }
                let opciones = await valorButton(result.data.result.opciones)
                setQuickButtons(opciones)
            }
        }

    };

    const handleQuickButtonClicked = async (newMessage) => {
        console.log("newMessage", newMessage)

        setQuickButtons([])
        addUserMessage(newMessage.sms);
        let result = await handleSubmit(newMessage.val)
        if (result.data.result.tipoMensaje === "texto") {
            addResponseMessage(result.data.result.texto);
        } else {
            if (result.data.result.tipoMensaje === "image") {
                addResponseMessage(result.data.result.texto);
                renderCustomComponent(Image, { src: result.data.result.image }, true, "response")
            } else {
                addResponseMessage(result.data.result.mensaje);
                if (result.data.result.indicacion !== "") {
                    addResponseMessage(result.data.result.indicacion)
                }
                let opciones = await valorButton(result.data.result.opciones)
                setQuickButtons(opciones)
            }
        }

    };

    return (

        <div className="App">
            <Widget
                handleNewUserMessage={handleNewUserMessage}
                handleQuickButtonClicked={handleQuickButtonClicked}
                profileAvatar={avatar}
                title="Converse con nosotros"
                subtitle=""
                titleAvatar={logoEmpresa}
                emojis={false}
                senderPlaceHolder="Escriba su mensaje"
                launcherOpenImg={iconoChat}

            />
        </div>
    );
}
export default App

