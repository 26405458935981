const axios = require("axios");
require('dotenv').config();

//------------------------Consultas a la API-------------------------------
function callAPI(message, conversationId) {
    const data = {
        message,
        conversationId
    };
    let url = process.env.REACT_APP_API_SOLICITUD_INSERT
    return new Promise(async (resolve, reject) => {
        try {
            axios({
                method: "post",
                url: url,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(data),
            })
                .then((result) => {
                    console.log("-------------------result----------------------", result)
                    if (result.status === 200) {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                })
                .catch((e) => {
                    console.log("ERROR => ", e);
                    reject(e);
                });
        } catch (error) {
            console.log("error al consumir API: ", error)
        }
    });
}


export default callAPI

